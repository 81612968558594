import React, { useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { UIContext } from '../contexts/UIContext';
import '../styles/InitialInterface.css'
import { Button1, ExampleButton, SelectionButton, Input } from '../components/Buttons';
import { generateLearningPathForUser, addPointsToUser, saveLearningPathForUser } from '../services/firebaseService';
import { calculatePoints } from '../services/utils';

function InitialInterface() {
    const {
        userId,
        topic, setTopic,
        experience, setExperience,
        additionalInfo, setAdditionalInfo,
    } = useContext(UserContext);
    const { setLoading, setInitializing, setPath } = useContext(UIContext);

    const [shakeTopic, setShakeTopic] = useState(false);
    const [shakeExperience, setShakeExperience] = useState(false);
    const [shakeMethod, setShakeMethod] = useState(false);
    const [selectedMethods, setSelectedMethods] = useState([]);

    const topics = [
        'Chess',
        'Spanish',
        'Piano',
        'Machine Learning',
    ];
    const experiences = [
        'I am a beginner',
        'I have already done... ',
    ];
    const methods = [
        'All',
        'YouTube',
        'Udemy',
        'Books',
        'Podcasts',
        'Articles',
    ];
    const additionalInfoExamples = [
        'As fast as possible',
        'Take your time',
        'I prefer videos',
    ];

    const toggleMethod = (method) => {
        if (method === 'All') {
            if (selectedMethods.includes('All')) {
                setSelectedMethods([]);
            } else {
                setSelectedMethods(methods);
            }
        } else {
            if (selectedMethods.includes(method)) {
                setSelectedMethods(prev => prev.filter(m => m !== method));
            } else {
                setSelectedMethods(prev => [...prev, method]);
            }
            
            // If 'All' was previously selected, deselect it when another method is selected
            if (selectedMethods.includes('All')) {
                setSelectedMethods(prev => prev.filter(m => m !== 'All'));
            }
        }
    };

    const handleSubmit = () => {
        if(!topic || !experience || selectedMethods.length === 0) {
            if(!topic) setShakeTopic(true);
            if(!experience) setShakeExperience(true);
            if(selectedMethods.length === 0) setShakeMethod(true);
            setTimeout(() => {
                setShakeTopic(false);
                setShakeExperience(false);
                setShakeMethod(false);
            }, 1000);
        } else {
            setLoading(true);
            const points = calculatePoints(topic, experience, selectedMethods, additionalInfo);
            generateLearningPathForUser(topic, experience, selectedMethods, additionalInfo)
                .then(response => {
                    try {
                        const learningPath = response;
                        setPath(learningPath);
                        if (userId) { 
                            saveLearningPathForUser(userId, learningPath);
                            addPointsToUser(userId, points);
                        }
                    } catch (err) {
                        console.error("Error parsing learning path:", err);
                    }
                })
                .catch(error => {
                    console.error("Error generating learning path:", error);
                })
                .finally(() => {
                    setInitializing(false);
                    setLoading(false);
                });
        }
    };

    return (
        <div className="InitialInterface">
            <main className="InitialInterface-main">
                <div className={`InitialInterface-main-section ${shakeTopic ? 'shake' : ''}`}>
                    <div className="example-buttons-container">
                        {topics.map((topic, i) => (
                            <ExampleButton key={i} onClick={() => setTopic(topic)} text={topic} />
                        ))}
                    </div>
                    <div className="InitialInterface-main-row">
                        <Input value={topic} placeholder="What do you want to learn?" onChange={(e) => { setTopic(e.target.value); setShakeTopic(false); }} />
                    </div>
                </div>
                <div className={`InitialInterface-main-section ${shakeExperience ? 'shake' : ''}`}>
                    <div className="example-buttons-container">
                        {experiences.map((experience, i) => (
                            <ExampleButton key={i} onClick={() => setExperience(experience)} text={experience} />
                        ))}
                    </div>
                    <div className="InitialInterface-main-row">
                        <Input value={experience} placeholder="What do you already know?" onChange={(e) => { setExperience(e.target.value); setShakeExperience(false); }} />
                    </div>
                </div>
                <div className={`InitialInterface-main-section ${shakeExperience ? 'shake' : ''}`}>
                    <div className="example-buttons-container">
                        {additionalInfoExamples.map((infoExample, i) => (
                            <ExampleButton key={i} onClick={() => setAdditionalInfo(infoExample)} text={infoExample} />
                        ))}
                    </div>
                    <div className="InitialInterface-main-row">
                        <Input value={additionalInfo} placeholder="Anything else to add?" onChange={(e) => { setAdditionalInfo(e.target.value); }} />
                    </div>
                </div>
                <div className={`InitialInterface-main-section ${shakeMethod ? 'shake' : ''}`}>
                    <div className="example-buttons-container">
                        {methods.map((method, i) => (
                            <SelectionButton
                                key={i}
                                selected={selectedMethods.includes(method)}
                                onClick={() => toggleMethod(method)}
                                text={method}
                            />
                        ))}
                    </div>
                </div>
                <footer className="InitialInterface-footer">
                    <Button1 text="Generate Learning Path" onClick={handleSubmit} />
                </footer>
            </main>
        </div>
    );
}

export default InitialInterface;