import React, { useContext, useCallback } from 'react';
import './App.css';
import InitialInterface from './interfaces/InitialInterface';
import MainInterface from './interfaces/MainInterface';
import AccountInterface from './interfaces/AccountInterface';
import SavedPathsInterface from './interfaces/SavedPathsInterface';
import NavBar from './components/NavBar';
import Loader from './components/Loader';
import { ThemeToggle } from './components/Buttons';
import { ThemeContext } from './contexts/ThemeContext';
import { UserContext } from './contexts/UserContext';
import { UIContext } from './contexts/UIContext';
import { observeAuthState } from './services/firebaseService';

function App() {
  const { theme, setTheme } = useContext(ThemeContext);
  const { initializing, loading, currentScreen } = useContext(UIContext);
  const { setUserId, setUsername, setUserEmail } = useContext(UserContext);

  const handleAuthStateChange = useCallback(
    (user) => {
      if (user) {
        setUserId(user.uid);
        setUsername(user.displayName);
        setUserEmail(user.email);
      }
    },
    [setUserId, setUsername, setUserEmail]
  );

  observeAuthState(handleAuthStateChange);

  let ScreenComponent;

  switch(currentScreen) {
      case 'account':
          ScreenComponent = AccountInterface;
          break;
      case 'savedPaths':
          ScreenComponent = SavedPathsInterface;
          break;
      case 'home': // fallthrough
      default:
          ScreenComponent = initializing ? InitialInterface : MainInterface;
  }

  return (
      <div className={`App ${theme === 'light' ? 'light' : ''}`}>
        <NavBar />
        <ThemeToggle onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')} />
        {loading && <Loader />}
        {!loading && <ScreenComponent />}
      </div>
  );
}

export default App;