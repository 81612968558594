import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, getDoc, doc, updateDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FREACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);

export const generateLearningPathForUser = async (topic, experience, methods) => {
    try {
        const generateLearningPath = httpsCallable(functions, 'generateLearningPath');
        const response = await generateLearningPath({ topic, experience, methods });
        return response.data;
    } catch (error) {
        console.error("Error calling generateLearningPath function: ", error);
        throw error;
    }
};

export const updateLearningPathForUser = async (path, request) => {
    try {
        const updateLearningPath = httpsCallable(functions, 'updateLearningPath');
        const response = await updateLearningPath({ path, request });
        return response.data;
    } catch (error) {
        console.error("Error calling updateLearningPath function: ", error);
        throw error;
    }
}

export const saveLearningPathForUser = async (userId, learningPath) => {
    try {
        const response = await httpsCallable(functions, 'saveLearningPath')({ userId, learningPath });
        return response.data;
    } catch (error) {
        console.error("Error calling saveLearningPath function: ", error);
        throw error;
    }
};

export const updatePathProgressForUser = async (userId, pathId, progress) => {
    try {
        const response = await httpsCallable(functions, 'updateProgress')({ userId, pathId, progress });
        return response.data;
    } catch (error) {
        console.error("Error calling updateProgress function: ", error);
        throw error;
    }
};

export const getLearningPathsForUser = async (userId) => {
    try {
        const response = await httpsCallable(functions, 'getLearningPaths')({ userId });
        return response.data;
    } catch (error) {
        console.error("Error calling getLearningPaths function: ", error);
        throw error;
    }
};

export const registerUser = async (email, password, username) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(userCredential.user, {
            displayName: username,
        });
        await userCredential.user.reload();
        return userCredential.user;
    } catch (error) {
        console.error("Error registering user: ", error);
        throw error;
    }
};

export const loginUser = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential.user;
    } catch (error) {
        console.error("Error logging in: ", error);
        throw error;
    }
};

export const logout = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        console.error("Error logging out: ", error);
        throw error;
    }
};

export const getUserData = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, `users/${userId}`));
        const user = auth.currentUser;
        const userData = userDoc.data();
        userData.displayName = user.displayName;
        return userData;
    } catch (error) {
        console.error("Error getting user data: ", error);
        throw error;
    }
};

export const addPointsToUser = async (userId, points) => {
    try {
        const userDoc = await getDoc(doc(db, `users/${userId}`));
        const userData = userDoc.data();
        const newPoints = userData.points + points;
        await updateDoc(doc(db, 'users', userId), {
            points: newPoints,
        });
    } catch (error) {
        console.error("Error adding points to user: ", error);
        throw error;
    }
};

export const updatePath = async (userId, path) => {
    try {
        const userDoc = await getDoc(doc(db, `users/${userId}`));
        const userData = userDoc.data();
        const learningPaths = userData.learningPaths;
        const updatedPaths = learningPaths.map(p => {
            if (p.id === path.id) {
                return path;
            }
            return p;
        });
        await updateDoc(doc(db, 'users', userId), {
            learningPaths: updatedPaths,
        });
        console.log("Updated path");
    } catch (error) {
        console.error("Error updating path: ", error);
        throw error;
    }
}

export const observeAuthState = (callback) => {
    return onAuthStateChanged(auth, user => {
        callback(user);
    });
};