import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import '../styles/AccountInterface.css';
import { ExampleButton, Button1, UnderlineButton, NormalInput } from '../components/Buttons';
import { registerUser, loginUser, logout, getUserData } from '../services/firebaseService';

function AccountInterface() {
    const { 
        userId,
        setUserId,
        userEmail, 
        setUserEmail,
        username,
        setUsername,
        points,
        setPoints,
    } = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    const [loggingIn, setLoggingIn] = useState(true);
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const firebaseErrorMap = {
        'auth/email-already-in-use': 'Email already in use',
        'auth/invalid-email': 'Invalid email',
        'auth/weak-password': 'Password must be at least 6 characters',
        'auth/user-not-found': 'User not found',
        'auth/wrong-password': 'Wrong password',
    };

    useEffect(() => {
        if (!userId) {
            return;
        }

        const fetchData = async () => {
            if (userId) {
                setLoggingIn(false);
                const userData = await getUserData(userId);
                setUsername(userData.displayName);
                setUserEmail(userData.email);
                setPoints(userData.points);
            }
        };
        setTimeout(() => {
            fetchData();
        }, 1000);
    }, [userId, setLoggingIn, setUsername, setUserEmail, setPoints]);

    const setErrorForSeconds = (error, seconds) => {
        setError(error);
        setTimeout(() => {
            setError(null);
        }, seconds * 1000);
    }
    
    
    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const user = await loginUser(userEmail, password);
            setUserId(user.uid);
            setUsername(user.displayName);
            setUserEmail(user.email);
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            setErrorForSeconds(firebaseErrorMap[error.code], 5);
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();
        setError(null);
        if (password !== confirmPassword) {
            setErrorForSeconds("Passwords do not match", 3);
            return;
        }
        if (!username || !userEmail || !password || !confirmPassword) {
            setErrorForSeconds("Please fill out all fields", 3);
            return;
        }
        try {
            const user = await registerUser(userEmail, password, username);
            setUserId(user.uid);
            setUsername(user.displayName);
            setUserEmail(user.email);
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.log(error);
            setErrorForSeconds(firebaseErrorMap[error.code], 5);
        }
    }

    const handleLogout = async () => {
        try {
            await logout();
            setUserId(null);
            setUsername('');
            setUserEmail('');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="AccountInterface">
            {userId ? (
                <main className="AccountInterface-main">
                    <div className="AccountInterface-main-account">
                        <h1 className="AccountInterface-main-title">{username}</h1>
                        <h2 className="AccountInterface-main-subtitle">{userEmail}</h2>
                        <h3 className={`AccountInterface-main-points ${theme === 'light' ? 'light' : ''}`}>
                            {points} <span className={`AccountInterface-main-points-text ${theme === 'light' ? 'light' : ''}`}>points</span>
                        </h3>
                    </div>
                    <ExampleButton text="Logout" onClick={() => handleLogout()} style={{ marginTop: 50, alignSelf: 'center' }} />
                </main>
            ) : (
                <main className="AccountInterface-main">
                    {loggingIn ? (
                        <form className="AccountInterface-form">
                            <div className="AccountInterface-form-header">
                                <h1 className="AccountInterface-form-header-title">Register</h1>
                                {error && <h4 className="AccountInterface-form-header-error">{error}</h4>}
                            </div>
                            <NormalInput placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
                            <NormalInput placeholder="Email" value={userEmail} onChange={e => setUserEmail(e.target.value)} />
                            <NormalInput placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} secure/>
                            <NormalInput placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} secure/>
                            <Button1 text="Register" onClick={(e) => handleRegister(e)} style={{ marginTop: 30 }} />
                            <div className="AccountInterface-form-footer">
                                <h4 className="AccountInterface-form-footer-text">Already have an account?</h4>
                                <UnderlineButton text="Login" onClick={() => setLoggingIn(false)} />
                            </div>
                        </form>
                    ) : (
                        <form className="AccountInterface-form">
                            <div className="AccountInterface-form-header">
                                <h1 className="AccountInterface-form-header-title">Login</h1>
                                {error && <h4 className="AccountInterface-form-header-error">{error}</h4>}
                            </div>
                            <NormalInput placeholder="Email" value={userEmail} onChange={e => setUserEmail(e.target.value)} />
                            <NormalInput placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} secure/>
                            <Button1 text="Login" onClick={(e) => handleLogin(e)} style={{ marginTop: 30 }} />
                            <div className="AccountInterface-form-footer">
                                <h4 className="AccountInterface-form-footer-text">Don't have an account?</h4>
                                <UnderlineButton text="Register" onClick={() => setLoggingIn(true)} />
                            </div>
                        </form>
                    )}
                    
                </main>
            )}
            <footer className="AccountInterface-footer">

            </footer>
        </div>
    );
}

export default AccountInterface;