import React, { useContext } from 'react';
import '../styles/Loader.css'
import { ThemeContext } from '../contexts/ThemeContext';

function Loader() {
    const { theme } = useContext(ThemeContext);

    return (
        <div className={`loader ${theme === 'light' ? 'light' : ''}`}>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
        </div>
    );
}

export default Loader;
