import React, { useContext } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { ThemeContext } from '../contexts/ThemeContext';
import '../styles/Buttons.css'

export function Slider({ value, onChange }) {
    const { theme } = useContext(ThemeContext);

    return (
        <label className={`slider ${theme === 'light' ? 'light' : ''}`}>
            <span className={`slider-title ${theme === 'light' ? 'light' : ''}`}>
                <span className={`age-highlight ${theme === 'light' ? 'light' : ''}`}>{value}</span> {value === 1 ? 'year' : 'years'} old
            </span>
            <input type="range" className="level" min="0" max="100" value={value} onChange={onChange} />
        </label>
    )
}

export function Input({ value, onChange, placeholder, secure }) {
    const { theme } = useContext(ThemeContext);

    return (
        <TextareaAutosize
            className={`input ${theme === 'light' ? 'light' : ''}`} 
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            minRows={1}
            maxRows={20}
            securetextentry={secure}
        />
    )
}

export function NormalInput({ value, onChange, placeholder, style, secure }) {
    const { theme } = useContext(ThemeContext);

    return (
        <input
            className={`input ${theme === 'light' ? 'light' : ''}`} 
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            style={style}
            type={secure ? "password" : "text"}
        />
    )
}

export function Button1({ text, onClick, style }) {
    const { theme } = useContext(ThemeContext);

    return (
        <button className={`button1 ${theme === 'light' ? 'light' : ''} dark`} onClick={onClick} style={style}>
            <strong>
                {text}
            </strong>
        </button>
    )
}

export function OutlineButton({ text, onClick, style, loading }) {
    const { theme } = useContext(ThemeContext);

    return (
        <button className={`outline-button ${theme === 'light' ? 'light' : ''}`} onClick={onClick} style={style}>
            {loading ? <div className="spinner"></div> : <strong>{text}</strong>}
        </button>
    )
}

export function UnderlineButton({ text, onClick, style }) {
    const { theme } = useContext(ThemeContext);

    return (
        <button className={`underline-button ${theme === 'light' ? 'light' : ''}`} onClick={onClick} style={style}>
            <strong>
                {text}
            </strong>
        </button>
    )
}


export function ExampleButton({ text, onClick, style }) {
    const { theme } = useContext(ThemeContext);

    return (
        <button className={`example-button ${theme === 'light' ? 'light' : ''}`} onClick={onClick} style={style}>
            <strong>
                {text}
            </strong>
        </button>
    )
}

export function NavButton({ text, onClick }) {
    const { theme } = useContext(ThemeContext);

    return (
        <button className={`nav-button ${theme === 'light' ? 'light' : ''}`} onClick={onClick}>
            <div className="nav-button-text">
                {text}
            </div>
        </button>
    )
}

export function SelectionButton({ text, onClick, selected }) {
    const { theme } = useContext(ThemeContext);

    return (
        <button
            className={`example-button big ${theme === 'light' ? 'light' : ''} ${selected ? 'selected' : ''}`}
            onClick={onClick}
        >
            <strong>
                {text}
            </strong>
        </button>
    );
}

export function ThemeToggle({ onClick }) {
    const { theme } = useContext(ThemeContext);

    return (
        <div className={`toggle-switch-container ${theme === 'light' ? 'light' : ''}`}>
            <div className="toggle-switch">
                <label className="switch-label" htmlFor="checkbox">
                    <input type="checkbox" className="checkbox" id="checkbox" onChange={onClick} />
                    <span className="toggle-slider"></span>
                </label>
            </div>  
        </div>
    )
}