import React, { useState, useContext, useEffect } from 'react';
import { UIContext } from '../contexts/UIContext';
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { PiPath } from "react-icons/pi";
import { NormalInput, OutlineButton } from '../components/Buttons';
import { updatePathProgressForUser, updateLearningPathForUser, updatePath } from '../services/firebaseService';
import '../styles/MainInterface.css';

function LearningResource({ resource, index }) {
    const { theme } = useContext(ThemeContext);
    return (
        <div key={index} className={`resource ${theme === 'light' ? 'light' : ''}`} onClick={() => window.open(resource.url)}>
            <div className="resource-inner">
                <h4>{resource.title}</h4>
                <p>{resource.description}</p>
            </div>
            <div className={`platformIcon ${resource.platform.toLowerCase()}`} >
                <h3>{resource.platform}</h3>
            </div>
        </div>
    );
}

function LearningStep({ step, index, currentStep, setNextStep }) {
    const [done, setDone] = useState(index < currentStep);
    const { theme } = useContext(ThemeContext);

    const handleToggle = () => {
        setDone(!done);
        if (!done && index === currentStep) {
            setNextStep();
        }
    };

    let buttonText;
    if (!done) {
        buttonText = index === currentStep ? 'Done' : 'Close';
    } else {
        buttonText = 'Reveal';
    }

    return (
        <div key={index} className={`step ${theme === 'light' ? 'light' : ''}`}>
            <div className={`step-header ${theme === 'light' ? 'light' : ''}`}>
                <h3>
                    {step.title}
                    {step.duration && <span className="duration"> ({step.duration})</span>}
                </h3>
                {index <= currentStep && 
                <div className="step-toggle" onClick={handleToggle}>
                    {buttonText}
                </div>}
            </div>
            {!done && 
            <div className={`step-inner ${theme === 'light' ? 'light' : ''}`}>
                <p>{step.description}</p>
                <div className="resources">
                    {step.resources && step.resources.map((resource, idx) => (
                        <LearningResource resource={resource} index={idx} key={idx} />
                    ))}
                </div>
            </div>}
        </div>
    );
}

function MainInterface() {
    const { path, setPath, setInitializing } = useContext(UIContext);
    const { userId } = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    const [currentStep, setCurrentStep] = useState(path.progress);
    const [userRequest, setUserRequest] = useState('');
    const [userRequestPlaceholder, setUserRequestPlaceholder] = useState('Don\'t use podcasts');

    const [modifyingPath, setModifyingPath] = useState(false);

    const setNextStep = () => {
        setCurrentStep(prev => prev + 1);
        updatePathProgressForUser(userId, path.id, currentStep + 1);
    }

    const tryAgain = () => {
        setInitializing(true); // sends back to InitialInterface
    };

    const modifyPath = () => {
        if (!userRequest) return;
        setModifyingPath(true);
        updateLearningPathForUser(path, userRequest)
            .then(response => {
                try {
                    const learningPath = response;
                    const parsedPath = JSON.parse(learningPath);
                    setPath(parsedPath);
                    if (userId) {
                        updatePath(userId, learningPath);
                    }
                } catch (err) {
                    console.error("Error parsing learning path:", err);
                }
            })
            .catch(error => {
                console.error("Error updating learning path: ", error);
            })
            .finally(() => {
                setModifyingPath(false);
            });
    };

    useEffect(() => {
        const userRequestPlaceholders = [
            'Don\'t use podcasts...',
            'I already know...',
            'I\'m not interested in...',
            'I don\'t like...',
            'Make it longer...',
            'I\'ve already done...',
        ];
        
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * userRequestPlaceholders.length);
            const placeholder = userRequestPlaceholders[randomIndex];
            let i = -1;
            setUserRequestPlaceholder('');
            const typeInterval = setInterval(() => {
                if (i < placeholder.length - 1) {
                    setUserRequestPlaceholder(prev => prev + placeholder[i]);
                    i++;
                } else {
                    clearInterval(typeInterval);
                }
            }, Math.floor(Math.random() * 50) + 25);
            return () => clearInterval(typeInterval);
        }, Math.floor(Math.random() * 5000) + 5000);
        return () => clearInterval(interval);
    }, []);

    if (!path) {
        return (
            <div className={`MainInterface-error ${theme === 'light' ? 'light' : ''}`}>
                <h2>Oops!</h2>
                <h3>Something went wrong.</h3>
                <button className="button1" onClick={tryAgain}>Try Again</button>
            </div>
        );
    }

    return (
        <div className={`MainInterface ${theme === 'light' ? 'light' : ''}`}>
            <div className="MainInterface-header">
                <h2 className={`mainIcon ${theme === 'light' ? 'light' : ''}`}><PiPath /></h2>
                <h2 className={`mainTitle ${theme === 'light' ? 'light' : ''}`}>{path.title}</h2>
                <div className={`experienceIcon ${theme === 'light' ? 'light' : ''}`}>
                    <h3 className={`experienceIconText ${theme === 'light' ? 'light' : ''}`}>{path.experience}</h3>
                </div>
            </div>
            <div className={`MainInterface-inner ${theme === 'light' ? 'light' : ''}`}>
                <p className={`mainDescription ${theme === 'light' ? 'light' : ''}`}>{path.description}</p>

                <div className={`divider ${theme === 'light' ? 'light' : ''}`}></div>

                <div className="roadmapContainer">
                    <div className="roadmapLine"></div>
                    <div className="roadmap">
                        {path && path.steps && path.steps.map((step, index) => (
                            <LearningStep 
                                step={step} 
                                index={index} 
                                currentStep={currentStep}
                                setNextStep={setNextStep}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <footer className={`MainInterface-footer ${theme === 'light' ? 'light' : ''}`}>
                <div className="MainInterface-footer-inner">
                    <NormalInput
                        placeholder={userRequestPlaceholder} 
                        value={userRequest} 
                        onChange={e => setUserRequest(e.target.value)}
                        style={{ width: '40em' }}
                    />
                    <OutlineButton 
                        text="Modify Path" 
                        onClick={modifyPath}
                        loading={modifyingPath}
                    />
                </div>
            </footer>
        </div>
    );
}

export default MainInterface;