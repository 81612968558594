import React, { createContext, useState } from 'react';

export const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [path, setPath] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("home");


  return (
    <UIContext.Provider value={{ loading, setLoading, initializing, setInitializing, path, setPath, currentScreen, setCurrentScreen }}>
      {children}
    </UIContext.Provider>
  );
};