import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext';
import { UIContext } from '../contexts/UIContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { getUserData } from '../services/firebaseService';
import '../styles/SavedPathsInterface.css';

function SavedPath({ path, index, onClick }) {
    const { theme } = useContext(ThemeContext);
    const numberOfSteps = path.steps.length;
    // calcuoate number of completed steps by progress path.progress
    const numberOfCompletedSteps = path.progress;
    const methods = path.steps.reduce((acc, step) => {
        const methods = step.resources.map(resource => resource.platform);
        return [...new Set([...acc, ...methods])];
    }, []);
    const percantageDone = Math.floor((numberOfCompletedSteps / numberOfSteps) * 100);

    return (
        <div className={`path ${theme === 'light' ? 'light' : ''}`} key={index} onClick={onClick}>
            <h3 className="path-title">{path.title}</h3>
            <p className="path-description">{path.description}</p>
            <p className="path-steps">{percantageDone}% <span className={`path-steps-text ${theme === 'light' ? 'light' : ''}`}>done</span></p>
            <div className="path-methods">
                {methods.map((method, idx) => (
                    <div className={`platformIcon ${method.toLowerCase()}`} key={idx}>
                        <h3>{method}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}

function SavedPathsInterface() {
    const { userId } = useContext(UserContext);
    const { setInitializing, setPath, setCurrentScreen } = useContext(UIContext);
    const [paths, setPaths] = useState([]);

    useEffect(() => {
        if (!userId) {
            return;
        }
        const fetchData = async () => {
            const userData = await getUserData(userId);
            if (!userData.learningPaths) {
                setPaths([]);
                return;
            }
            setPaths(userData.learningPaths);
        };
        fetchData();
    }, [userId]);

    const handleSelectPath = (path) => {
        setPath(path);
        setInitializing(false);
        setCurrentScreen('home');
    };

    return (
        <div className="SavedPathsInterface">
            {(paths.length === 0) ? (
                <div className="SavedPathsInterface-empty">
                    <h2>You don't have any saved paths yet!</h2>
                    {userId ? (
                        <p>Go to the home screen to generate a new path.</p>
                    ) : (
                        <p>Sign in to save paths.</p>
                    )}
                </div>
            ) : (
                <main className="SavedPathsInterface-main">
                    <div className="SavedPathsInterface-main-inner">
                        {paths && paths.map((path, index) => (
                            <SavedPath path={path} index={index} key={index} onClick={() => handleSelectPath(path)} />
                        ))}
                    </div>
                </main>
            )}

            <footer className="SavedPathsInterface-footer">
                
            </footer>
        </div>
    );
}

export default SavedPathsInterface;