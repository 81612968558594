
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [username, setUsername] = useState('');
  const [points, setPoints] = useState(0);
  const [progress, setProgress] = useState(0);
  const [topic, setTopic] = useState('');
  const [experience, setExperience] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  return (
    <UserContext.Provider value={{
      userId,
      setUserId,
      userEmail,
      setUserEmail,
      username,
      setUsername,
      points,
      setPoints,
      progress,
      setProgress,
      topic,
      setTopic,
      experience,
      setExperience,
      additionalInfo,
      setAdditionalInfo,
    }}>
      {children}
    </UserContext.Provider>
  );
};