import { useContext } from 'react';
import { UIContext } from '../contexts/UIContext';
import { ThemeContext } from '../contexts/ThemeContext';
import '../styles/NavBar.css'
import { PiHouse, PiUser, PiBooks } from 'react-icons/pi';
import { NavButton } from './Buttons';

function NavBar() {
    const { setCurrentScreen, setInitializing } = useContext(UIContext);
    const { theme } = useContext(ThemeContext);

    const handleHomeClick = () => {
        setCurrentScreen('home');
        setInitializing(true);
    };

    return (
        <div className="navBar">
            <div className="navBar-inner">
                <NavButton className="navButton" onClick={handleHomeClick} text={<PiHouse className={`icon ${theme === 'light' ? 'light' : ''}`} />} />
                <NavButton className="navButton" onClick={() => setCurrentScreen('account')} text={<PiUser className={`icon ${theme === 'light' ? 'light' : ''}`} />} />
                <NavButton className="navButton" onClick={() => setCurrentScreen('savedPaths')} text={<PiBooks className={`icon ${theme === 'light' ? 'light' : ''}`} />} />
            </div>
        </div>
    );
}

export default NavBar;
