export function calculatePoints(topic, experience, selectedMethods, additionalInfo) {
    let points = 0;

    // Base point for generating a path
    points += 1;

    // Points for diverse or focused learning
    if (selectedMethods.length > 2) {
        points += selectedMethods.length; // reward diverse learning
    } else if (selectedMethods.length === 1) {
        points += 3; // bonus for focused learning (only one method chosen)
    } else if (selectedMethods.length === 2) {
        points += 2; // slightly lesser bonus for choosing two methods
    }

    // Bonus for additional info
    if (additionalInfo && additionalInfo.trim() !== "") {
        points += 2;
    }

    return points;
}